import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import connexionServeur from "../Helpers/ConnexionServeur";
import ProduitItem from "./ProduitItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

const ListeProduits = ()=>{
    let BASE_URL = "https://rimexinter.com"
    
    const [produits, setProduits] = useState([])
    const [categorie, setCategorie] = useState("")
    const [sousCategorie, setSousCategorie] = useState("")
    const [photoSousCategorie,setPhotoSousCategorie] = useState("")

    const {aliasCategorie , aliasSousCategorie} = useParams()

    useEffect(() => {
        getProduits()
        getPhotoSousCategorie()
    }, [])

    const getProduits =async ()=>{
        let donnees = new FormData()
        donnees.append("alias", aliasSousCategorie)

        let response = await connexionServeur("produits/getProduitByAliasSousCategorie", donnees)
        let data = await response.json()
        setProduits(data.donnees)
        setCategorie(data.donnees[0].categorie)
        setSousCategorie(data.donnees[0].sous_categorie)
    }

    const getPhotoSousCategorie =async ()=>{
        let donnees = new FormData()
        donnees.append("alias", aliasSousCategorie)

        let response = await connexionServeur("sous_categories/getPhotoSousCategorieByAlias", donnees)
        let data = await response.json()
        // setPhotoSousCategorie("url('http://localhost/rimex" + data.donnees.photo + "'")
        setPhotoSousCategorie("url('" + BASE_URL + data.donnees.photo + "'")
    }

    return(
        <div className="container">
            <div className="my-5 ">
                <button className="d-block ms-auto btn btn-outline-dark">
                    <Link to={'../'+aliasCategorie} className="text-decoration-none">
                        <FontAwesomeIcon icon={faBackward} className="me-3" />
                        Retour
                    </Link>
                </button>
            </div>
            <div className="banniere-categorie" style={{backgroundImage:photoSousCategorie}}>
                <div className="texte-banniere-categorie">{categorie + " > " + sousCategorie}</div>
            </div>
            <h5> 
                <Link to={'..'} className="text-decoration-none">{"Catégories > "} </Link> <Link to={'../'+aliasCategorie} className="text-decoration-none">{categorie}</Link>{' > ' + sousCategorie}
            </h5>
            <div className="d-flex justify-content-evenly flex-wrap">
                {
                    produits.map((produit, index)=><ProduitItem key={produit.idProduit} produit={produit} index={index} />)
                }
            </div>
        </div>
    )
}

export default ListeProduits