import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import connexionServeur from "../Helpers/ConnexionServeur"

const SousCategorieItem = props=>{
    let BASE_URL = "http://rimexinter.com"
    let sousCategorie = props.sousCategorie

    return(
        <Link to={sousCategorie.alias} className="text-decoration-none text-dark">
            <div className="card m-3 p-0 shadow rounded" style={{width: 250}}>
                <div className="card-header text-center">
                    <h6 className="card-subtitle my-2 text-muted">{sousCategorie.categorie}</h6>
                </div>
                <img className="img-fluid card-img-top" height={100} src={BASE_URL + sousCategorie.photo} alt={sousCategorie.sous_categorie}/>
                <div className="card-body text-center">
                    <h5 className="card-title ">{sousCategorie.sous_categorie}</h5>
                </div>
            </div>
        </Link>
    )
}

export default SousCategorieItem