import { useEffect, useState } from "react"
import { NavBar } from "../Composants/NavBar"
import connexionServeur from "../Helpers/ConnexionServeur"
import CategorieItem from "../Composants/CategorieItem"
import Contact from "../Composants/Contacts"
import { Marques } from "../Composants/Marques"
import stock from "../images/stock.jpg"
import entrepot from "../images/entrepot.jpg"
import shipping from "../images/shipping.jpg"
import { Link } from "react-router-dom"

export const Accueil=()=>{

    const [categories, setCategories] = useState([])
    const [currentCategorie, setCurrentCategorie] = useState(null)

    useEffect(() => {
        getCategories()
    }, [])

    const getCategories =async ()=>{
        let response = await connexionServeur("categories/getCategories", [])
        let data = await response.json()
        setCategories(data.donnees)
    }

    return(
        <div className="p-0 m-0 bg-secondaire">
            <div className="banniere2 bg-primaire">
                <h1 className="m-5 texte-banniere couleur-secondaire fade-in ">
                    R<span className="couleur-accent-fonce">I</span>MEX 
                    <br />
                    <small className="texte-banniere-bis">International <span className="couleur-accent-fonce">Inc.</span></small>
                </h1>
            </div>

            <div className="container-fluid my-5 py-5 ">
                <div className="py-5 text-center">
                    <h2 className=" display-3">Spécialiste dans le domaine du second oeuvre du batiment, de la construction et du bricolage.</h2>
                </div>

            </div>

            <div className="container-fluid py-5 bg-primaire">
                <div className="row mb-5">
                    <div className="col-12 col-lg-12 mx-auto">
                        <div className="row p-5">
                            <div className="col-12 col-lg-6 p-y">
                            <h1 style={{fontSize:70}} className="couleur-secondaire texte-titre-description">
                                    RACHAT DE STOCK
                                </h1>
                                <p className=" texte-description" >
                                    Nous achetons des produits invendus, stocks dormants,
                                    produits excédentaires auprès des fabricants et distributeurs
                                    situés en Europe, principalement en Italie, en France, en Espagne,
                                    en Allemagne et en Belgique.
                                    <br />
                                    Nos marchandises sont neuves et emballées sans défaut.
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 ">
                                <img src={entrepot} alt="stock" className="img-fluid shadow" />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 mx-auto">
                        <div className="row p-5">
                            <div className="col-12 col-lg-6 ">
                                <img src={shipping} alt="port" className="img-fluid shadow" />
                            </div>
                            <div className="col-12 col-lg-6">
                                <h1  className="couleur-secondaire texte-titre-description">
                                    DESTOCKAGE À L'EXPORT
                                </h1>
                                <p className="texte-description">
                                    Nous achetons des produits invendus, stocks dormants,
                                    produits excédentaires auprès des fabricants et distributeurs
                                    situés en Europe, principalement en Italie, en France, en Espagne,
                                    en Allemagne et en Belgique.
                                    <br />
                                    Nos marchandises sont neuves et emballées sans défaut.
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>


            <div className="container-fluid description bg-secondaire bg-image" >
                <div className="carte-description ">
                    <h4>Vous êtes importateur basé en Afrique ? </h4>
                    <p>
                        Achetez des stocks de marchandises invendues stockées en Europe dans le domaine de la construction.
                    </p>
                    <Link to={"/inscription"} className="w-50">
                        <button className="bt-inscription bg-secondaire couleur-fonce">
                            Créez un compte
                        </button>
                    </Link>
                </div>
                <div className="carte-description-alt bg-accent-fonce text-white">
                    <h4>Vous êtes fabricant européen ? </h4>
                    <p>
                        Vendez vos marchandises invendues à l'export en Afrique sans perturber vos réseaux de vente habituels.
                    </p>
                    <Link to={"/inscription"} className="w-50">
                        <button className="bt-inscription bg-secondaire">
                            Inscrivez-vous
                        </button>
                    </Link>
                    
                </div>
            </div>

            <div className="container-fluid bg-white py-5  text-center">
                <h2 className=" texte-titre-description text-center my-3">Les marques</h2>
                    
                <p className="">
                    Nous travaillons avec les plus grandes marques du marché. Nous sommes en mesure de vous proposer des produits de qualité à des prix compétitifs. 
                </p>

                <Marques />
                
            </div>

            <Contact />
        </div>
    )
}