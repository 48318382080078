import philips from "../images/philips.png"
import abb from "../images/abb.png"
import briloner from "../images/briloner.png"
import franke from "../images/franke.png"
import legrand from "../images/legrand.png"
import nobili from "../images/nobili.png"
import roca from "../images/roca.png"
import schock from "../images/schock.png"

export const Marques = ()=>{

    return(
        <div className="marques ">
            <img src={philips} alt="logo philips" />
            <img src={abb} alt="logo philips" />
            <img src={briloner} alt="logo philips" />
            <img src={nobili} alt="logo philips" />
            <img src={schock} alt="logo philips" />
            <img src={legrand} alt="logo philips" />
            <img src={franke} alt="logo philips" />
            <img src={roca} alt="logo philips" />
        </div>
    )
}