import { useEffect, useState } from "react"
import CategorieItem from "../Composants/CategorieItem"
import connexionServeur from "../Helpers/ConnexionServeur"

export const ListeCategories=()=>{
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getCategories()
    }, [])

    const getCategories =async ()=>{
        let response = await connexionServeur("categories/getCategories", [])
        let data = await response.json()
        setCategories(data.donnees)
    }

    const setCurrentCategorie=()=>{
        
    }

    return(
         <div className="">
            <div className="row py-5">
                <div className="col col-lg-10 mx-auto">
                    <p className="justifier fs-1">
                        Voyez toutes nos catégories de produits
                    </p>
                    <p className="justifier fs-3">
                        Nous exportons les lots en destockage des plus grandes marques européennes dans le domaine de la construction et du bricolage.
                    </p>
                </div>
            </div>
            
            <div className="grille px-5">
                {
                    categories.map((categorie, index)=><CategorieItem key={categorie.idCategorie} categorie={categorie} index={index} />)
                }
            </div>
         </div>
    )
}