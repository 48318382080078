import'../CSS/Apropos.css'
import image from '../images/stock.jpg'
export const APropos = ()=>{

    return(
        <div>
            <div className="container-fluid" >
                <div className="mini-banniere move-left">
                    <div className="carte-mini-banniere bg-accent-fonce couleur-secondaire ">
                        À propos de nous
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='zone'>
                    <div className='zone-titre'>
                        <h4>ACHAT</h4>
                        <h1 className='bordure-gauche'>Produits invendus, stocks dormants</h1>
                    </div>
                    <div className='zone-texte'>
                        <p className="texte-apropos ">
                            Nous achetons des produits invendus, stocks dormants, produits excédentaires auprès des fabricants et distributeurs situés en Europe, principalement en Italie, en France, en Espagne, en Allemagne et en Belgique.
                            Nos marchandises sont neuves et emballées sans défaut.                
                        </p>
                    </div>
                </div>
                
            </div>

            <div className='container-fluid carte-zone-produits'>
                <div className='texte-zone-produits'>
                    Des produits renouvelés chaque semaine.
                </div>
            </div>

            <div className='container'>
                <div className='zone'>
                    
                    <div className='zone-texte '>
                        <p className="texte-apropos ">
                            Nous achetons des produits invendus, stocks dormants, produits excédentaires auprès des fabricants et distributeurs situés en Europe, principalement en Italie, en France, en Espagne, en Allemagne et en Belgique.
                            Nos marchandises sont neuves et emballées sans défaut.                
                        </p>
                    </div>
                    <div className='zone-titre text-end'>
                        <h4 className='text-end'>VENTE</h4>
                        <h1 className='bordure-droite'>Produits invendus, stocks dormants</h1>
                    </div>
                </div>
            </div>
            

            


        
        
        </div>
    )
}