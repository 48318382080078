import { createContext } from "react";


export const MonContext = createContext({
    idClient: 0,
    entreprise: "",
    setIdClient: (id)=>{
        MonContext.idClient = id;
    },
    setEntreprise: (entreprise)=>{
        MonContext.entreprise = entreprise;
    }
})
