import { Link, useLocation } from 'react-router-dom'
import '../CSS/Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

export const Footer = () => {
    const location = useLocation();
    if (location.pathname === '/inscription' || location.pathname === '/connexion') {
        return null;
    }

    return (
        <div className="container-fluid p-3 bg-dark couleur-secondaire">
            <div className="row px-5">
                <div className="col-12 col-md-4">
                    <div className="titre-footer">
                        LIEN UTILES
                    </div>
                    <div className='texte-footer'>
                        <ul className='ul-footer'>
                            <li><Link to={"/"} className='lien'>Page d'accueil</Link></li>
                            <li><Link to={"/produits"} className='lien'>Produits</Link></li>
                            <li><Link to={"/apropos"} className='lien'>À propos</Link></li>
                            <li><Link to={"/inscription"} className='lien'>Créez un compte</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="titre-footer">
                        NOS COORDONNÉES
                    </div>
                    <div className='texte-footer'>
                        <div>
                            <FontAwesomeIcon icon={faPhone} className='me-3'/> 514-555-5555
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faEnvelope} className='me-3'/><a href="mailto:mail@mail.com" className='lien'>mail@mail.com</a>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="titre-footer">
                        SUIVEZ-NOUS
                    </div>
                    <div className='brands'>
                        <FontAwesomeIcon icon={faFacebook} className='mx-2'/>
                        <FontAwesomeIcon icon={faInstagram}  className='mx-2'/>
                        <FontAwesomeIcon icon={faLinkedin}  className='mx-2'/>
                    </div>
                </div>
            </div>   
        </div>         
    )
}