import { faCamera, faPeace, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Link, Navigate } from "react-router-dom"
import connexionServeur from "../Helpers/ConnexionServeur"

const CategorieItem = props=>{
    let BASE_URL = "http://rimexinter.com"
    let categorie = props.categorie
    
    let lien = "url('" + BASE_URL + categorie.photo + "'"
    
    
    return(
        <Link to={props.categorie.alias} className="text-decoration-none width-carte-categorie" >
            <div className="carte-categorie" id={categorie.idCategorie} style={{backgroundImage:lien}}>
                <div className="texte-carte">
                    {categorie.categorie}
                    {/* <h6 className="text-muted">Sous titre</h6> */}
                </div>
            </div>
        </Link>
        

            // <div className="card col-12 col-lg-3 m-1 p-0  rounded pointeur hover"  id={categorie.idCategorie} style={{width:200}}>
            //     <Link to={props.categorie.alias} className="text-decoration-none text-dark">
            //     <img className="img-fluid card-img-top" src={"http://localhost/rimex" + categorie.photo} alt={categorie.categorie}/>
            //     <div className="card-body ">
            //         <h5 className="card-title text-center ">{categorie.categorie}</h5>
            //     </div>
            //     </Link>
            // </div>
        
    )
}

export default CategorieItem