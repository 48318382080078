import { useState } from "react";
import css from "../CSS/Inscription.css"
import connexionServeur from "../Helpers/ConnexionServeur";
import  pays  from "../pays.json"


export const Inscription = ()=>{

    const [entreprise, setEntreprise] = useState("");
    const [numeroEntreprise, setNumeroEntreprise] = useState("");
    const [typeClient, setTypeClient] = useState("");
    const [personne, setPersonne] = useState("");
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [mdp, setMdp] = useState("");
    const [mdp2, setMdp2] = useState("");

    const [paysEntreprise, setPaysEntrprise] = useState("")

    const handleEntreprise = (e)=>{
        setEntreprise(e.target.value);
    }
    const handleNumeroEntreprise = (e)=>{
        setNumeroEntreprise(e.target.value);
    }
    const handleTypeClient = (e)=>{
        setTypeClient(e.target.value);
    }
    const handlePersonne = (e)=>{
        setPersonne(e.target.value);
    }
    const handleTelephone = (e)=>{
        setTelephone(e.target.value);
    }
    const handleEmail = (e)=>{
        setEmail(e.target.value);
    }
    const handleMdp = (e)=>{
        setMdp(e.target.value);
    }
    const handleMdp2 = (e)=>{
        setMdp2(e.target.value);
    }

    const gestionPaysEntreprise=(e)=>{
        setPaysEntrprise(e.target.value)
    }

    //form validation
    const validateForm = (e)=>{
        e.preventDefault();
        let inputs = document.querySelectorAll('#infos input');
        let selects = document.querySelectorAll('select');
        let valid = true;
        
        inputs.forEach(input=>{
            if(input.type === "password"){
                //valid password
                let mdpValid = true;
                let mdp = document.querySelector('#mdp');
                let mdp2 = document.querySelector('#mdp2');
                if(mdp.value === "" ){
                    mdp.classList.add('is-invalid');
                    valid = false;
                    mdpValid = false;
                }else{
                    mdp.classList.remove('is-invalid');
                }
                if(mdp2.value === "" ){
                    mdp2.classList.add('is-invalid');
                    valid = false;
                    mdpValid = false;
                }else{
                    mdp2.classList.remove('is-invalid');
                }
                if(mdpValid){
                    if(mdp.value !== mdp2.value){
                        mdp.classList.add('is-invalid');
                        mdp2.classList.add('is-invalid');
                        valid = false;
                    }else{
                        mdp.classList.remove('is-invalid');
                        mdp2.classList.remove('is-invalid');
                    }
                }
            }
            else
            {
                if(input.value === ""){
                    input.classList.add('is-invalid');
                    valid = false;
                }else{
                        input.classList.remove('is-invalid');
                }
            }
            
        });

        selects.forEach(select=>{
            if(select.value === ""){
                select.classList.add('is-invalid');
                valid = false;
            }else{
                select.classList.remove('is-invalid');
            }
        });

        //valid email
        let email = document.querySelector('#email');
        let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if(!emailRegex.test(email.value)){
            email.classList.add('is-invalid');
            valid = false;
        }else{
            email.classList.remove('is-invalid');
        }
        
        if(valid){
            sendData();
        }
    }

    const sendData = async ()=>{
        let donnees = new FormData()

        donnees.append('nomEntreprise', entreprise)
        donnees.append('numeroEntreprise', numeroEntreprise)
        donnees.append('typeClient', typeClient)
        donnees.append('contact', personne)
        donnees.append('telephone', telephone)
        donnees.append('email', email)
        donnees.append('mdp', mdp)
        donnees.append('pays', paysEntreprise)

        let response = await connexionServeur("clients/addClient", donnees)
        let data = await response.json()

        console.log(data)
        if(data.resultat === true){
            alert("Votre compte a été créé avec succès")
        }
        else
        {
            alert(data.message)
        }
    }




    return(

        <div className="container-fluid ">
            <div className="row">
                <div className="col-12 col-md-2 bg-accent-clair text-white d-none d-md-block">
                    <h2><strong>RIMEX</strong></h2>
                    <h6 className="">
                        International Inc.
                    </h6>
                </div>
                <div className="col-12 col-md-10 bg-white">
                    <div className="row py-3 border-bottom-1">
                        <h2 className="titre">Création de compte</h2>
                        <p className="">
                            Veuillez remplir les champs ci-dessous pour créer votre compte.
                        </p>
                    </div>
                    <form id="infos" noValidate onSubmit={validateForm} className="p-3">
                        <div className="row mb-3">
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="entreprise">Nom de l'entreprise</label>
                                    <input type="text" className="form-control" id="entreprise"  onChange={handleEntreprise}/>
                                    <div className="invalid-feedback">
                                        Veuillez entrer le nom de l'entreprise
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="numeroEntreprise">Numéro d'entreprise</label>
                                    <input type="text" className="form-control" id="numeroEntreprise"  onChange={handleNumeroEntreprise}/>
                                    <div className="invalid-feedback">
                                        Veuillez entrer le numéro d'entreprise
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group ">
                                    <label htmlFor="pays">Pays</label>
                                    <select className="form-select" id="pays" value={paysEntreprise}  onChange={gestionPaysEntreprise}>
                                        <option value="">Choisir un pays</option>
                                        {pays.map((pays, index)=>{
                                            return <option key={index} value={pays.name}>{pays.name}</option>
                                        }
                                        )}
                                    </select>
                                    <div className="invalid-feedback">
                                        Veuillez choisir votre pays
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="typeClient">Type de compte</label>
                                    <select className="form-control" id="typeClient" onChange={handleTypeClient}>
                                        <option value="">Choisir un type de compte</option>
                                        <option value="acheteur">Acheteur</option>
                                        <option value="fournisseur">Fournisseur</option>
                                    </select>
                                    <div className="invalid-feedback">
                                        Veuillez choisir un type de client
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="personne">Personne contact</label>
                                    <input type="text" className="form-control" id="personne"  onChange={handlePersonne}/>
                                    <div className="invalid-feedback">
                                        Veuillez entrer le nom de la personne contact
                                    </div>
                                </div>
                            </div>   
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="telephone">Téléphone</label>
                                    <input type="text" className="form-control" id="telephone"  onChange={handleTelephone}/>
                                    <div className="invalid-feedback">
                                        Veuillez entrer le numéro de téléphone
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email">Adresse courriel</label>
                                    <input type="email" className="form-control" id="email" onChange={handleEmail}/>
                                    <div className="invalid-feedback">
                                        Veuillez entrer une adresse courriel valide
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="mdp">Mot de passe</label>
                                    <input type="password" className="form-control" id="mdp"  onChange={handleMdp}/>
                                    <div className="invalid-feedback">
                                        Veuillez entrer un mot de passe
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="mdp2">Confirmer le mot de passe</label>
                                    <input type="password" className="form-control" id="mdp2"  onChange={handleMdp2}/>
                                    <div className="invalid-feedback">
                                        Veuillez confirmer le mot de passe
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-12 text-center">
                                <button type="submit" className="btn btn-primary">Créer le compte</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
                        
        </div>

        // <div className="conteneur bg-secondaire">
        //     <div className='gauche bg-accent-clair text-white d-none d-md-block'>
        //         <h2><strong>RIMEX</strong></h2>
        //         <h6 className="">
        //             International Inc.
        //         </h6>
        //     </div>
        //     <div className='droite bg-white'>    
        //         <div className="row py-3 border-bottom-1">
        //             <h2 className="titre">Création de compte</h2>
        //             <p className="">
        //                 Veuillez remplir les champs ci-dessous pour créer votre compte.
        //             </p>
        //         </div>
        //         <form id="infos" noValidate onSubmit={validateForm}>       
        //             <div className="row">
        //                     <div className="col-12 col-md-6">
        //                         <div className="mb-3">
        //                             <label htmlFor="entreprise" className="form-label">Nom de l'entreprise</label>
        //                             <input type="text" className="form-control" id="entreprise" onChange={handleEntreprise} />
        //                         </div>
        //                     </div>
        //                     <div className="col-12 col-md-6">
        //                         <div className="mb-3">
        //                             <label htmlFor="numeroEntreprise" className="form-label">Numéro d'entreprise</label>
        //                             <input type="text" className="form-control" id="numeroEntreprise" onChange={handleNumeroEntreprise}/>
        //                         </div>
        //                     </div>
        //             </div>

        //             <div className="row">
        //                     <div className="col-12 col-md-6">
        //                         <div className="mb-3">
        //                             <label htmlFor="numeroEntreprise" className="form-label">Type de client</label>
        //                             <select className="form-select" aria-label="Default select example" onChange={handleTypeClient}>
        //                                 <option value="">Choisir...</option>
        //                                 <option value="acheteur">Acheteur</option>
        //                                 <option value="fournisseur">Fournisseur</option>
        //                             </select>
        //                         </div>
        //                     </div>
        //                     <div className="col-12 col-md-6">
        //                         <div className="mb-3">
        //                             <label htmlFor="personne" className="form-label">Personne à contacter</label>
        //                             <input type="text" className="form-control" id="personne" onChange={handlePersonne}/>
        //                         </div>
        //                     </div>
        //             </div>

        //             <div className="row">
        //                 <div className="col-12 col-md-6">
        //                     <label htmlFor="telephone" className="form-label">Numéro de téléphone</label>
        //                     <input type="email" className="form-control" id="telephone" onChange={handleTelephone}/>
        //                 </div>
        //                 <div className="col-12 col-md-6">
        //                     <label htmlFor="email" className="form-label" >Adresse électronique</label>
        //                     <input type="email" className="form-control" id="email" onChange={handleEmail} placeholder="ex: monentreprise@mail.com"/>
        //                 </div>
        //             </div>

        //             <div className="row mt-5">
        //                 <h4 className="card-subtitle my-3">Mot de passe</h4>
                        
        //                 <div className="col-12 col-md-6">
        //                     <label htmlFor="mdp" className="form-label mb-0">Mot de passe</label>
        //                     <p className="card-text mt-0">
        //                         Veuillez choisir un mot de passe sécuritaire.
        //                     </p>
        //                     <input type="password" className="form-control" id="mdp" onChange={handleMdp}/>
        //                 </div>
        //                 <div className="col-12 col-md-6">
        //                     <label htmlFor="mdp2" className="form-label mb-0">Confirmation du mot de passe</label>
        //                     <p className="card-text mt-0">
        //                         Entrez le même mot de passe que précédemment.
        //                     </p>
        //                     <input type="password" className="form-control" id="mdp2" onChange={handleMdp2}/>
        //                 </div>
        //             </div>

        //             <div className="text-center">
        //                 <button type="submit" className="btn btn-primary text-center mt-3">Inscription</button>
        //             </div>

        //         </form>
        //     </div>
        // </div>
        

    )
}

