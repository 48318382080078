import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import SousCategorieItem from "./SousCategorieItem";
import connexionServeur from "../Helpers/ConnexionServeur";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ListeSousCategories = ()=>{
    let BASE_URL = "https://rimexinter.com"
    
    const [sousCategories, setSousCategories] = useState([])
    const [categorie, setCategorie] = useState("")
    const [photoCategorie,setPhotoCategorie] = useState("")

    const {aliasCategorie} = useParams()

    useEffect(() => {
        getSousCategories()
        getPhotoCategorie()
    }, [])

    const getSousCategories =async ()=>{
        let donnees = new FormData()
        donnees.append("alias", aliasCategorie)

        let response = await connexionServeur("sous_categories/getSousCategorieByAlias", donnees)
        let data = await response.json()
        setSousCategories(data.donnees)
    }

    const getPhotoCategorie =async ()=>{
        let donnees = new FormData()
        donnees.append("alias", aliasCategorie)

        let response = await connexionServeur("categories/getPhotoCategorieByAlias", donnees)
        let data = await response.json()
        // setPhotoCategorie("url('http://localhost/rimex" + data.donnees.photo + "'")
        setPhotoCategorie("url('" + BASE_URL + data.donnees.photo + "'")
        setCategorie(data.donnees.categorie)
    }

    return(
        <div className="container pt-5">
            <div className="my-5 ">
                <button className="d-block ms-auto btn btn-outline-dark">
                    <Link to={'..'} className="text-decoration-none">
                        <FontAwesomeIcon icon={faArrowLeft} className="me-3" />
                        Retour
                    </Link>
                </button>
            </div>
            <div className="banniere-categorie" style={{backgroundImage:photoCategorie}}>
                <div className="texte-banniere-categorie">{categorie}</div>
            </div>

            <h5><Link to={'..'} className="text-decoration-none">{"Catégories > "} </Link> {categorie}</h5>
            <div className="d-flex justify-content-evenly flex-wrap">
                {
                    sousCategories.map((sousCategorie, index)=><SousCategorieItem key={sousCategorie.idSousCategorie} sousCategorie={sousCategorie} index={index} />)
                }
            </div>
        </div>
    )
}

export default ListeSousCategories