import { useEffect, useState } from "react"
import CategorieItem from "../Composants/CategorieItem"
import connexionServeur from "../Helpers/ConnexionServeur"
import { Outlet } from 'react-router-dom';
import ListeSousCategories from "../Composants/ListeSousCategories";
import ListeProduits from "../Composants/ListeProduits";
import { ListeCategories } from "../Composants/ListeCategories";

export const Produits=()=>{

    const [idCategorie, setidCategorie] = useState(0)
    const [idSousCategorie, setidSousCategorie] = useState(0)
    const [idProduit, setidProduit] = useState(0)

    const [afficherCategories, setAfficherCategories] = useState("")
    const [afficherSousCategories, setAfficherSousCategories] = useState("d-none")
    const [afficherProduits, setAfficherProduit] = useState("d-none")

    const [currentCategorie, setCurrentCategorie] = useState(null)
    const [animation, setAnimation] = useState("")

    const gestionClic=(origine, objet)=>{

        if(origine == "categorie")
        {
            setCurrentCategorie(objet)
            
        }
    }

    // make fade in anomation


    return(
        <div>
            <div className="container-fluid" >
                <div className="mini-banniere move-left">
                    <div className="carte-mini-banniere bg-fonce couleur-secondaire">
                        Nos produits
                    </div>
                </div>
            </div>

            
        
        {/* <div className="container-fluid p-0">
            <div className="d-flex vh-25 p-4 bg-dark text-white justify-content-end align-items-end ">
                <h1 className="m-5 texte-banniere-petit ">
                    Produits 
                </h1>
            </div>    
        </div> */}

        <div className="container p-0">

            <Outlet />
            
        </div>
        </div>
    )
}