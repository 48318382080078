import { Link } from "react-router-dom"

const Erreur = ()=>{
    return(
        <div className="constainer-fluid vh-100">
            <div className="row align-items-center text-center h-100">
                <div className="col-12">
                    <h1 className="display-1">404</h1>
                    <h3>La page que vous recherchez n'existe pas</h3>
                    <Link to="/">
                        <button className="btn btn-primary">Revenir à la page d'accueil</button>
                    </Link>
                </div>
            </div>
        </div>
        
    )
}

export default Erreur