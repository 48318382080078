import { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import connexionServeur from "../Helpers/ConnexionServeur";
import { MonContext } from "../Helpers/MonContext";

export const Connexion = ()=>{

    const [email, setEmail] = useState("");
    const [mdp, setMdp] = useState("");
    const [message, setMessage] = useState("")

    const context = useContext(MonContext);

    const connexion = async (e)=>{
        e.preventDefault();

        let valid = true;

        let inputEmail = document.getElementById("inputEmail");
        let inputMdp = document.getElementById("inputMdp");

        if(email === ""){
            inputEmail.classList.add("is-invalid");
            valid = false;
        }
        else{
            inputEmail.classList.remove("is-invalid");
        }

        if(mdp === ""){
            inputMdp.classList.add("is-invalid");
            valid = false;
        }
        else{
            inputMdp.classList.remove("is-invalid");
        }

        if(valid)
        {
            let donnees = new FormData();
            donnees.append("email", email);
            donnees.append("mdp", mdp);

            let response = await connexionServeur("clients/login", donnees)
            let data = await response.json()
            console.log(data)
            if(data.resultat == true)
            {
                context.setIdClient(data.donnees.idClient);
                context.setEntreprise(data.donnees.nomEntreprise);
            }
            else
            {
                setMessage(data.donnees)
            }
        }
    }

    const handleEmail = (e)=>{
        setEmail(e.target.value)
    }

    const handleMdp = (e)=>{
        setMdp(e.target.value)
    }

    const resetForm = ()=>{
        document.getElementById("formulaire").reset();
    }

    const toInscription = ()=>{
        document.getElementById("modalConnexion").style.display = "none";
        document.getElementById("formulaire").reset();
        Navigate("/inscription");
    }

    return(
        <div className="container vh-100">
            <div className="row h-100 align-items-center">
                <div className="col-12 col-md-6 offset-md-3">
                    <div className="card">
                        <div className="card-header bg-black text-white">
                            <h1 className="text-center">Connexion</h1>
                        </div>
                        <div className="card-body">
                            <p className="text-center text-danger">{message}</p>
                            <form id="formulaire">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Adresse courriel</label>
                                    <input type="email" className="form-control" onChange={handleEmail} id="inputEmail"/>
                                    <div className="invalid-feedback">
                                        Veuillez entrer un email
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Mot de passe</label>
                                    <input type="password" className="form-control" onChange={handleMdp} id="inputMdp"/>
                                    <div className="invalid-feedback">
                                        Veuillez entrer un mot de passe
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary w-100" onClick={connexion} >Connexion</button>
                            </form>
                            <div className="mt-4 text-center lien">
                                <Link to={"/inscription"} onClick={toInscription} className="text-decoration-none fs-3">S'inscrire</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}