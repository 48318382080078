import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur"

const ProduitItem = props=>{
    let BASE_URL = "http://rimexinter.com"
    let produit = props.produit

    
    return(
        <div className="card m-3 p-0 shadow rounded" style={{width: 250}}>
            <div className="card-header text-center">
                <h6 className="card-subtitle my-2 text-muted">{produit.categorie}</h6>
            </div>
            <img className="img-fluid card-img-top" src={BASE_URL + produit.photo} alt={produit.produit}/>
            <div className="card-body text-center">
                <h5 className="card-title ">{produit.produit}</h5>                
                <h6 className="card-subtitle my-2 text-muted">{produit.sous_categorie}</h6>
            </div>
        </div>
    )
}

export default ProduitItem