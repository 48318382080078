import logo from './logo.svg';
import './App.css';
import './CSS/Couleurs.css';
import './CSS/Animations.css';
import { Accueil } from './Pages/Accueil';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Erreur from './Pages/Erreur';
import { NavBar } from './Composants/NavBar';
import { Produits } from './Pages/Produits';
import { ListeCategories } from './Composants/ListeCategories';
import ListeSousCategories from './Composants/ListeSousCategories';
import ListeProduits from './Composants/ListeProduits';
import { Inscription } from './Pages/Inscription';
import { APropos } from './Pages/APropos';
import { Footer } from './Composants/Footer';
import { Connexion } from './Pages/connexion';
import { MonContext } from './Helpers/MonContext';
import { useState } from 'react';

function App() {

  const [idClient, setIdClient] = useState(0);
  const [entreprise, setEntreprise] = useState("");


  return (
    <MonContext.Provider value={
      {
        idClient: idClient,
        entreprise: entreprise,
        setIdClient: setIdClient,
        setEntreprise: setEntreprise
      }
    }>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route exact path='/' element={<Accueil />} />
          <Route path='/produits' element={<Produits />} >
              <Route index element={<ListeCategories />} />
              <Route exact path=':aliasCategorie' element={<ListeSousCategories />} />
              <Route exact path=':aliasCategorie/:aliasSousCategorie' element={<ListeProduits />} />
          </Route>
          <Route path='/apropos' element={<APropos />} ></Route>
          <Route path='/connexion' element={<Connexion />} ></Route>
          <Route path='/inscription' element={<Inscription />} />
          <Route path='*' element={<Erreur />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </MonContext.Provider>
  );
}

export default App;
