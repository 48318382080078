import { Link, Navigate } from "react-router-dom";

export const ModalConnexion = ()=>{

    const resetForm = ()=>{
        document.getElementById("formulaire").reset();
    }

    const toInscription = ()=>{
        document.getElementById("modalConnexion").style.display = "none";
        document.getElementById("formulaire").reset();
        Navigate("/inscription");
    }

 return(
        <div className="modal fade " id="modalConnexion" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalConnexionLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalConnexionLabel">Connexion</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}></button>
                    </div>
                    <div className="modal-body bg-primaire text-white p-4">
                        <form id="formulaire">
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Adresse courriel</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Mot de passe</label>
                                <input type="password" className="form-control" id="exampleInputPassword1"/>
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Connexion</button>
                        </form>
                        <div className="mt-4 text-center lien">
                            <Link to={"/inscription"} onClick={toInscription}>Pas encore inscrit?</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

 )
}