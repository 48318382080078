import { useEffect, useState } from "react"
import  pays  from "../pays.json"
import connexionServeur from "../Helpers/ConnexionServeur"

const Contact = () => {

    const [paysEntreprise, setPaysEntrprise] = useState(pays[0].name)
    const [entreprise, setEntreprise] = useState("")
    const [telephone, setTelephone] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const gestionPaysEntreprise=(e)=>{
        setPaysEntrprise(e.target.value)
    }

    const gestionEntreprise=(e)=>{
        setEntreprise(e.target.value)
    }

    const gestionTelephone=(e)=>{
        setTelephone(e.target.value)
    }

    const gestionEmail=(e)=>{
        setEmail(e.target.value)
    }

    const gestionMessage=(e)=>{
        setMessage(e.target.value)
    }

    const envoiFormulaire= async (e)=>{
        e.preventDefault()

        let donnees = new FormData()
        donnees.append("paysEntreprise", paysEntreprise)
        donnees.append("entreprise", entreprise)
        donnees.append("telephone", telephone)
        donnees.append("email", email)
        donnees.append("message", message)

        

        let response = await connexionServeur("contact/", donnees)
        
        let data = await response.json()
        //console.log(data)
    }


    
    return (
        <div className="container-fluid d-flex flex-wrap justify-content-evenly align-items-center bg-primaire py-5">
            <div className="carte-contact bg-accent-fonce text-white">
                <div className="border-bottom mb-4">
                    <h1 className=" texte-titre-description ">Contactez nous</h1>
                </div>
                <p className="justifier fs-3 ">
                    Vous avez des questions, laissez nous un message avec vos coordonnées, 
                    nous vous répondrons dans les plus brefs délais.
                 </p>
            </div>
            <div className="carte-formulaire ">
                <form className=" text-white my-5 " onSubmit={envoiFormulaire}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group py-3">
                                <label htmlFor="nom">Nom de l'entreprise</label>
                                <input type="text" className="form-control" id="nom" value={entreprise} minLength={2} required onChange={gestionEntreprise} />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group py-3">
                                <label htmlFor="telephone">Numéro de téléphone</label>
                                <input type="tel" className="form-control" id="telephone" value={telephone} required onChange={gestionTelephone} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group py-3">
                                <label htmlFor="email">Adresse électronique</label>
                                <input type="email" className="form-control" id="email" value={email} required onChange={gestionEmail} />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group py-3">
                                <label htmlFor="pays">Pays</label>
                                <select className="form-select" id="pays" value={paysEntreprise} required onChange={gestionPaysEntreprise}>
                                    {pays.map((pays, index)=>{
                                        return <option key={index} value={pays.name}>{pays.name}</option>
                                    }
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group py-3">
                                <label htmlFor="message">Message</label>
                                <textarea className="form-control" id="message" rows="3" value={message} onChange={gestionMessage}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">    
                            <button type="submit" className="bt-contact bg-accent-fonce text-white">Envoyer</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        
    )
}

export default Contact