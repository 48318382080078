import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { useContext, useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom'
import { ModalConnexion } from '../Modals/ModalConnexion';
import { MonContext } from '../Helpers/MonContext';

export const NavBar = ()=>{

    const context = useContext(MonContext);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [sticky, setSticky] = useState("");
    const [texteConnexion, setTexteConnexion] = useState("Se connecter");
    //const [active, setActive] = useState('');

    const location = useLocation();
    const active = location.pathname

    const handleScroll = () => {
        const position = window.pageYOffset;
        if(position >=400)
        {
            setSticky("fixed-top")
            //console.log(position);
        }
        else
            setSticky("")
    };

    useEffect(()=>
    {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
    }
    }, [])



    useEffect(() => {
        if(context.idClient > 0)
            setTexteConnexion(context.entreprise);
        else
            setTexteConnexion("Se connecter");
    }, [context.idClient])


    return(
        <div>
            <nav className={"navbar navbar-expand-lg  navbar-dark bg-primaire couleur-secondaire texte-navbar " + sticky}>
                <div className="container">
                    <Link to={"/"} className="navbar-brand" href="#">RIMEX</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse " id="navbarNavAltMarkup">
                        <div className="navbar-nav ms-auto">
                            <Link className={active=="/" ? "nav-link mx-2 active" : "nav-link mx-2"} aria-current="page" to={"/"} id='accueil' >Accueil</Link>
                            <Link className={active=="/produits" ? "nav-link mx-2 active" : "nav-link mx-2"} to={"/produits"} id='produits' >Produits</Link>
                            <Link className={active=="/apropos" ? "nav-link mx-2 active" : "nav-link mx-2"} to={"/apropos"} id='apropos' >À Propos</Link>
                            <Link className={active=="/connexion" ? "nav-link mx-2 active" : "nav-link mx-2"} to={"/connexion"} id='connexion' ><FontAwesomeIcon icon={faUser} className="me-2"/>{texteConnexion}</Link>
                            {/* <button className=" bt-navbar bg-accent-fonce text-white mx-2 p-2 " href="#" data-bs-toggle="modal" data-bs-target="#modalConnexion"><FontAwesomeIcon icon={faUser} className="me-2"/>Compte</button> */}
                            
                        </div>
                    </div>
                </div>
            </nav>
            <ModalConnexion />
        </div>
    )
}